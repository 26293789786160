import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Responsive from '../components/responsive'
import Seo from '../components/seo'
import Title from '../components/titles'

const NotFoundPage = () => (
    <>
        <Seo title="404" />
        <Layout withMiniHeader>
            <Section>
                <Responsive>
                    <Title as="h1" size="large" caps withDot>
                        Page introuvable
                    </Title>
                    <p>
                        <Link to="/">Retourner à l'accueil</Link>
                    </p>
                </Responsive>
            </Section>
        </Layout>
    </>
)

const Section = styled.section`
    min-height: 75vh;
`

export default NotFoundPage
